/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react";
import Article, {
	ExperienceCard,
	MediaCard,
	UseCaseCard,
} from "../article/article";
import ContactCard from "../contact-card/contact-card";
import ExpertisePill from "../expertise-pill/expertise-pill";
import SmallNav from "../small-nav/small-nav";
import { DrawerModal } from "../modal-container/modal-container";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { QuestionaireComponent } from "../../screens/verify";
import { OtherQuestionArr } from "../../pages/setup";
import { manageOrganization } from "../../data/Reducers/OrganizationReducer";
import { BiBadgeCheck } from "react-icons/bi";

export const RightDrawer = ({ drawer, toggleShowDrawer }) => {
	let { organization, auth } = useSelector(s => s),
		dispatch = useDispatch(),
		drawerMenu = [
			{ name: "About", show: true },
			{ name: "Contact", show: true },
			{ name: "History", show: auth?.user?.privilege === "organization" },
			{ name: "Media", show: true },
			{ name: "Use Cases", show: true },
			{ name: "Experience", show: auth?.user?.privilege === "organization" },
		],
		[state, setState] = useState({ company: drawer }),
		handleMultipleCall = async (type, endpoint) => {
			try {
				let res = await axios.get(endpoint);
				// console.log({ res: res?.data }, type);
				setState(st => {
					let newSt = { ...st };
					newSt[type] = res?.data?.data;
					return newSt;
				});
			} catch (err) {
				if (err?.response?.status === 429 || err?.response?.status === 405)
					toast.error(err?.response?.data ? err?.response?.data : err?.message);
				console.log({ err });
			}
		};
	const [activeNav, setActivenav] = useState("About");
	const handleNav = item => {
		setActivenav(item);
	};

	useEffect(() => {
		if (!organization?.companiesArchived || !organization?.companies) {
			dispatch(
				manageOrganization("get", {
					companies: "companies",
					active: "active",
				})
			);
			dispatch(
				manageOrganization("get", {
					companies: "companies",
					statusText: "archived",
				})
			);
			dispatch(
				manageOrganization("get", {
					companies: "companies",
					statusText: "recommendation",
				})
			);
		}
	}, [organization?.companiesArchived, dispatch, organization?.companies]);

	useEffect(() => {
		if (drawer?.company || drawer?.companyId) {
			handleMultipleCall(
				"media",
				`/api/v1/media?company=${
					drawer?.company?._id ||
					drawer?.company ||
					drawer?.companyId ||
					drawer?._id ||
					""
				}`
			);
			handleMultipleCall(
				"associate",
				`/api/v1/organization/companies?company=${
					drawer?.company?._id ||
					drawer?.company ||
					drawer?.companyId ||
					drawer?._id ||
					""
				}&type=staff`
			);
			handleMultipleCall(
				"usecases",
				`/api/v1/usecases?company=${
					drawer?.company?._id ||
					drawer?.company ||
					drawer?.companyId ||
					drawer?._id ||
					""
				}`
			);
			handleMultipleCall(
				"rawdata",
				`/api/v1/rawdata?company=${
					drawer?.company?._id ||
					drawer?.company ||
					drawer?.companyId ||
					drawer?._id ||
					""
				}`
			);
			handleMultipleCall(
				"experience",
				`/api/v1/experience?company=${
					drawer?.company?._id ||
					drawer?.company ||
					drawer?.companyId ||
					drawer?._id ||
					""
				}`
			);
		}
		if (drawer) {
			let com = drawer;

			if (drawer?.companyId) {
				let docs = organization?.companies?.docs?.concat(
					organization?.companiesArchived?.docs
				);
				let findCom = docs?.find(it => it?._id === drawer?.companyId);
				if (findCom) com = findCom;
			}

			setState({ ...state, company: com });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [drawer]);

	// console.log({ drawer, state });
	return (
		<DrawerModal
			show={drawer}
			close={() => {
				setState(null);
				setActivenav("About");
				toggleShowDrawer();
			}}>
			<div className="h-screen overflow-y-auto scrollbar-hide">
				<div className="bg-white p-4 pb-0 h-fit sticky top-0 z-30">
					{(state?.company?.dateUpdated || state?.company?.updatedAt) && (
						<div className="flex justify-end">
							<small className="text-gray-400">
								Last Modified:{" "}
								{moment(
									state?.company?.dateUpdated || state?.company?.updatedAt
								)?.format("L")}
							</small>
						</div>
					)}
					<div className="flex gap-2 items-center">
						<img
							src={
								state?.company?.image?.url ||
								require("../../assets/Company Logo.png")
							}
							alt={state?.company?.organizationName || ""}
							className="h-16 rounded-md w-16"
						/>
						<div>
							<p className="text-xl font-semibold">
								{state?.company?.organizationName || ""}
							</p>
							<p className="text-sm text-gray-400 flex items-center gap-2">
								{state?.company?.email || ""}{" "}
								{!["archived", "recommendation", "pending"]?.includes(
									state?.company?.statusText
								) && (
									<>
										<BiBadgeCheck size={20} color="green" />
									</>
								)}
							</p>
						</div>
					</div>
					<SmallNav
						menu={drawerMenu?.filter(it => it?.show)?.map(it => it?.name)}
						handleNav={handleNav}
						active={activeNav}
					/>
				</div>
				<div className="p-4">
					{activeNav === "About" && <Description state={state} />}
					{activeNav === "Contact" && <Contact state={state} />}
					{activeNav === "History" && <History state={state} />}
					{activeNav === "Media" && <Media state={state} />}
					{activeNav === "Use Cases" && <UseCases state={state} />}
					{activeNav === "Experience" && <Experience state={state} />}
				</div>
			</div>
		</DrawerModal>
	);
};
export default RightDrawer;

const Description = ({ state }) => {
	console.log({ state });
	let { auth } = useSelector(s => s);
	if (!state?.company) return;
	let {
		companyBio,
		yearFounded,
		websiteUrl,
		headQuarters,
		companySize,
		targetPopulation,
		recommenderPurpose,
		statusText,
	} = state?.company;

	return (
		<div className="overflow-y-auto">
			{auth?.user?.privilege === "organization" &&
				statusText === "recommendation" &&
				recommenderPurpose && (
					<div className="mb-3">
						<p className="font-semibold">Purpose of Recommendation</p>
						<p className="text-xs text-gray-500">{recommenderPurpose}</p>
					</div>
				)}
			{companyBio && (
				<>
					<p className="font-semibold">Description</p>
					<p className="text-xs text-gray-500">{companyBio}</p>
				</>
			)}
			{state?.rawdata?.docs?.map((it, i) => (
				<div className="mt-8" key={i}>
					<p className="font-semibold">{it?.category}</p>
					<div className="mt-4 flex flex-wrap gap-2">
						{it?.data?.map((ic, x) => (
							<ExpertisePill value={ic} key={i} />
						))}
					</div>
				</div>
			))}
			<div className="grid grid-cols-2 gap-4 mt-4">
				{yearFounded && (
					<Article
						title={"Year Founded"}
						text={moment(yearFounded).format("YYYY")}
					/>
				)}
				{websiteUrl && <Article title={"Website"} text={websiteUrl} />}
				{headQuarters && <Article title={"Headquarters"} text={headQuarters} />}
				<Article title={"Company size"} text={companySize || "N/A"} />
				<div className="col-span-2">
					<Article title={"Target population"} text={targetPopulation} />
				</div>
			</div>
		</div>
	);
};

const Contact = ({ state }) => {
	return (
		<div>
			<div className="bg-[#C8EBFE] p-4 rounded">
				<p className="text-sm font-semibold mb-2">Primary details</p>
				<ContactCard user={state?.company} />
			</div>
			<div className="bg-white p-4 rounded my-4">
				<p className="text-sm font-semibold mb-2">Contact Person details</p>
				<ContactCard user={state?.company?.contactPerson} />
			</div>
			<p className="text-sm font-semibold mb-2">Associated contact details</p>
			<div className="mt-8 space-y-6">
				{state?.associate?.docs?.map((it, i) => (
					<ContactCard key={i} user={it} />
				))}
			</div>
		</div>
	);
};

const History = ({ state }) => {
	return (
		<div>
			<p className="font-medium text-md">Have you engaged this user?</p>
			<div className="grid grid-cols-2 gap-4 my-4">
				<div className="flex items-center">
					<input
						type="checkbox"
						className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
					/>
					<label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
						Contacted
					</label>
				</div>
				<div className="flex items-center">
					<input
						type="checkbox"
						className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
					/>
					<label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
						Not Contacted
					</label>
				</div>
				<div className="flex items-center">
					<input
						type="checkbox"
						className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
					/>
					<label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
						Ongoing Partnership
					</label>
				</div>
				<div className="flex items-center">
					<input
						type="checkbox"
						className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
					/>
					<label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
						Ongoing Conversation
					</label>
				</div>
			</div>
			<p className="font-medium text-sm mt-8">Engagement</p>
			<div className="grid grid-cols-2 gap-4 mt-1 mb-4">
				<Article
					title={"Call"}
					text={state?.company?.phoneNumber}
					style={"bg-white border-tetiary"}
				/>
				<Article
					title={"Email"}
					text={state?.company?.email}
					style={"bg-white border-tetiary"}
				/>
			</div>
		</div>
	);
};

const Media = ({ state }) => {
	return (
		<div>
			<div>
				<p className="text-xl font-semibold mb-4">Relevant Media</p>
			</div>
			<div className="space-y-4">
				{state?.media?.docs?.map((it, i) => (
					<MediaCard
						key={i}
						style={"bg-white"}
						title={it?.fileName}
						text={it?.file?.url}
						date={moment(it?.createdAt).format("MMMM YYYY")}
					/>
				))}
			</div>
		</div>
	);
};

const UseCases = ({ state }) => {
	return (
		<div>
			<div>
				<p className="text-xl font-semibold mb-4">Use Cases</p>
			</div>
			<div className="space-y-4">
				{state?.usecases?.docs?.map((it, i) => (
					<UseCaseCard
						style={"bg-white"}
						key={i}
						title={it?.fileName}
						text={it?.url || it?.file?.[0]?.url}
						date={moment(it?.createdAt).format("MMMM YYYY")}
						item={it}
					/>
				))}
			</div>
		</div>
	);
};

const Experience = ({ state }) => {
	let [formInfo, setFormInfo] = useState(null),
		{ workspace } = useSelector(s => s);

	useEffect(() => {
		let experience = state?.experience?.docs?.[0];
		if (experience || workspace?.data) {
			let findRef = experience;
			setFormInfo(workspace?.data?.questions);
			if (workspace?.data) {
				let mappedSection = [];

				for (let s = 0; s < workspace?.data?.questions.length; s++) {
					const element = workspace?.data?.questions[s];
					let findOther = findRef?.questions?.find(
						it => it?.queId === element?.queId
					);
					if (findOther) {
						let newSubTitle = [];
						for (let ts = 0; ts < element?.subTitle.length; ts++) {
							const elementSub = element?.subTitle[ts];
							let findOtherSub = findOther?.subTitle?.find(
								it => it?.kipId === elementSub?.kipId
							);
							if (findOtherSub) {
								newSubTitle?.push({
									...findOtherSub,
									kpiname: elementSub?.kpiname,
								});
							} else {
								newSubTitle?.push(elementSub);
							}
						}
						mappedSection?.push({
							...findOther,
							subTitle: newSubTitle,
							question: element?.question,
						});
					} else {
						mappedSection?.push(element);
					}
				}
				console.log({
					mappedSection,
					workspace: workspace?.data?.questions,
					experience,
				});
				setFormInfo(mappedSection);
			}
		}
	}, [workspace, state?.experience]);

	const handleFormTitle = (event, formIndex) => {};

	return (
		<div>
			<div>
				<p className="text-xl font-semibold mb-4">Experience</p>
			</div>
			<div className="space-y-4">
				{state?.experience?.docs?.map((it, i) => (
					<ExperienceCard
						style={"bg-white"}
						key={i}
						title={it?.type}
						item={it}
					/>
				))}
			</div>
			<div className="space-y-4 pb-5 md:pb-10">
				{OtherQuestionArr?.map((ite, i) => (
					<fieldset key={i} className="flex flex-col">
						<label className="text-sm md:text-lg f-bold tracking-wide">
							{ite?.question}
						</label>
						{ite?.subquestion && (
							<>
								<small className="b text-gray-400 block">
									{ite?.subquestion}
								</small>
							</>
						)}
						<textarea
							className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 bg-gray-100 rounded-lg w-full py-4 focus:outline-none focus:border-blue-400 border-none  placeholder:text-sm placeholder:italic"
							placeholder={ite?.placeholder || ite?.question}
							row={6}
							style={{
								resize: "none",
								height: "10rem",
							}}
							id={ite?.name}
							name={ite?.name}
							// onChange={textChange}
							value={state?.experience?.docs?.[0]?.[ite?.name]}
							readOnly
						/>
					</fieldset>
				))}
			</div>
			<div className="space-y-4 pb-5 md:pb-10">
				<p>Other Created Questions</p>
				<QuestionaireComponent
					readOnly
					formInfo={formInfo}
					handleFormTitle={handleFormTitle}
				/>
			</div>
		</div>
	);
};
