import React, { useContext, useEffect, useState } from "react";
import ExpertisePill from "../../components/expertise-pill/expertise-pill";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { RiMenuLine } from "react-icons/ri";
import { BsGridFill } from "react-icons/bs";
import { LuSettings2 } from "react-icons/lu";
import { RiArrowLeftSLine } from "react-icons/ri";
import { RiArrowRightSLine } from "react-icons/ri";
import { IoAddOutline } from "react-icons/io5";
import canvasGrid from "../../assets/canvasGrid.png";
import { DrawerModal } from "../../components/modal-container/modal-container";
import MapVisual, {
	SortMainData,
} from "../../components/map-visual/map-visual";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../data/Context";
import { manageMap, resetMap } from "../../data/Reducers/MapReducer";
import { useLocation, useNavigate } from "react-router-dom";
import organisation from "../../data1";
// import SearchDropdown, {
//   SearchDropdownSelectCountry,
//   SearchExportUL,
// } from "../../components/search-dropdown/search-dropdown";

const Map = () => {
	// const canvas = canvasRef.current
	const location = useLocation();
	const [selectFirst, setSelectFirst] = useState(true);
	const [selectSecond, setSelectSecond] = useState(false);
	const [filter, setFilter] = useState("map");
	const [firstItem, setFirstItem] = useState("");
	const [firstTitle, setFirstTitle] = useState("");
	const [secondItem, setSecondItem] = useState("");
	const [secondTitle, setSecondTitle] = useState("");
	const [toPaginate, setToPaginate] = useState("");
	const [mainData1, setMainData1] = useState([]);
	const [mainData2, setMainData2] = useState([]);
	let { map, rawdata, auth, organization } = useSelector(s => s),
		[mapData, setMapData] = useState(Object.keys(organisation[0])),
		dispatch = useDispatch(),
		{ orgTheme } = useContext(GlobalState),
		navigate = useNavigate();

	// console.log({ rawdata });
	// console.log({ mapData });
	console.log({ organizations: organization });

	const [drawer, setDrawer] = useState(false);
	const toggleShowDrawer = d => {
		setDrawer(drawer ? null : d);
	};

	// pagination start
	const itemsPerPage = 50; // Number of items per page
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState();
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	// const currentItems = getAllEmployees(data).slice(
	// indexOfFirstItem,
	// indexOfLastItem
	// );

	const setPages = org => {
		// console.log({ orgLength: org.length });
		// console.log({ org });
		// const totalPages = Math.ceil(mainData2?.length / itemsPerPage);
		// setTotalPages(totalPages);
	};

	const handlePageChange = pageNumber => {
		navigate(`?page=${pageNumber}`);
		// setCurrentPage(Number(location.search.split("=")[1]));
	};

	useEffect(() => {
		if (!location.search) {
			navigate("?page=1");
		}
		if (!firstItem) {
			navigate("?page=1");
		}
		setCurrentPage(Number(location.search.split("=")[1]));
	}, [location.search, navigate, firstItem]);

	useEffect(() => {
		if (firstItem) {
			if (map?.data?.relations) {
				let findItem = map?.data?.relations?.find(
					it => it?.[firstItem?.category]
				);
				if (findItem) setFirstTitle(firstItem?.category);
				else setFirstTitle("");
			}
		}
		if (secondItem) {
			if (map?.data?.relations) {
				let findItem = map?.data?.relations?.find(
					it => it?.[secondItem?.category]
				);
				if (findItem) setSecondTitle(secondItem?.category);
				else setSecondTitle("");
			}
		}
	}, [firstItem, map?.data, secondItem]);

	useEffect(() => {
		let main = mainData2,
			pag = "second";
		if (mainData1?.length > mainData2?.length) {
			main = mainData1;
			pag = "first";
		}
		setToPaginate(pag);
		main = main?.filter(it => it?.isSelected);
		// console.log({ main });
		if (main?.length > 0) {
			const totalPages = Math.ceil(main?.length / itemsPerPage);
			setTotalPages(totalPages);
		}
	}, [mainData2, mainData1]);

	// End of pagination

	useEffect(() => {
		if (map?.mapFilter?.docs?.length > 0)
			setMapData(
				// auth?.user?.privilege === "organization"
				// 	?
				[
					{
						category: "company",
						categoryId: "company",
						displayName: "Organization",
					},
					...map?.mapFilter?.docs,
				]?.filter(d => d)
				// : map?.mapFilter?.docs
			);
		else {
			if (process.env.NODE_ENV === "production") setMapData([]);
		}
		console.log(map?.mapFilter?.docs);
	}, [map?.mapFilter, auth?.user]);

	useEffect(() => {
		if (auth?.user?.privilege === "organization") {
			if (rawdata?.data) {
				if (rawdata?.data?.docs?.length === 0) navigate("/get-started");
			}
			if (map?.mapFilter) {
				if (map?.mapFilter?.docs?.length === 0)
					navigate(map?.isMappable === "true" ? "/data" : "/get-started");
				if (map?.isMappable !== "true") navigate("/get-started");
			}
		}
	}, [rawdata?.data, navigate, auth?.user, map?.isMappable, map.mapFilter]);

	useEffect(() => {
		if (firstItem) {
			dispatch(
				manageMap("get", {
					firstFilter: firstItem?.categoryId,
					secondFilter: secondItem?.categoryId,
					organisation: "org",
				})
			);
		}
	}, [dispatch, firstItem, secondItem]);

	useEffect(() => {
		dispatch(resetMap());
		dispatch(
			manageMap("get", {
				map: "map",
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="flex items-center justify-center h-fit relative">
			<div
				style={{ background: `url(${canvasGrid})` }}
				className="fixed inset-0"></div>

			{/* <div className="absolute inset-x-0 h-fit top-4 flex justify-between z-50"> */}
			<div className="flex gap-x-4 absolute h-fit top-4 left-4 z-40 w-fit">
				<div className="space-y-2">
					{mapData
						?.filter(
							item =>
								item?.categoryId !== "company" &&
								item?.displayName !== "Organization"
						)
						?.filter(item => (selectFirst ? item : item === mapData?.[1]))
						?.map((item, idx) => (
							<ExpertisePill
								key={idx}
								value={
									selectFirst
										? item?.displayName || item?.category || item
										: firstItem?.displayName ||
										  firstItem?.category ||
										  firstItem ||
										  item?.displayName ||
										  item?.category ||
										  item
								}
								bg={"bg-white"}
								state={
									item === firstItem || (firstItem && !selectFirst && true)
								}
								toggleState={() => {
									if (item === firstItem) {
										setFirstItem("");
										navigate(`?page=${1}`);
									} else if (item === secondItem) {
									} else {
										navigate(`?page=${1}`);
										setFirstItem(item);
									}
									setSelectFirst(!selectFirst);
								}}
							/>
						))}
					<ExpertisePill
						value={!selectFirst ? <IoIosArrowDown /> : <IoIosArrowUp />}
						css={"w-fit mx-auto"}
						toggleState={() => setSelectFirst(!selectFirst)}
					/>
				</div>
				<span>vs</span>
				<div className="space-y-2">
					{mapData
						?.filter(
							item =>
								item?.categoryId === "company" &&
								item?.displayName === "Organization"
						)
						?.filter(item => (selectSecond ? item : item === mapData?.[0]))
						?.map((item, idx) => (
							<ExpertisePill
								key={idx}
								value={
									selectSecond
										? item?.displayName || item?.category || item
										: secondItem?.displayName ||
										  secondItem?.category ||
										  secondItem ||
										  item?.displayName ||
										  item?.category ||
										  item
								}
								bg={"bg-white"}
								state={
									item === secondItem || (secondItem && !selectSecond && true)
								}
								toggleState={() => {
									if (item === secondItem) {
										setSecondItem("");
										navigate(`?page=${1}`);
									} else if (item === firstItem) {
									} else if (firstItem) {
										navigate(`?page=${1}`);
										setSecondItem(item);
									}
									setSelectSecond(!selectSecond);
								}}
							/>
						))}
					<ExpertisePill
						value={!selectSecond ? <IoIosArrowDown /> : <IoIosArrowUp />}
						css={"w-fit mx-auto hidden"}
						toggleState={() => setSelectSecond(!selectSecond)}
					/>
				</div>
			</div>

			<div
				className="bg-white p-2 h-fit flex items-center gap-x-2 text-main cursor-pointer text-xl  absolute top-4 right-4 z-40 w-fit"
				style={{
					color: orgTheme || "",
				}}>
				<span
					className={`${
						filter === "table" && "bg-main text-white"
					} p-1 rounded-md`}
					style={{
						background: orgTheme && filter === "table" ? orgTheme : "",
					}}
					onClick={() => setFilter("table")}>
					<RiMenuLine />
				</span>
				<span
					className={`${
						filter === "map" && "bg-main text-white"
					} p-1 rounded-md`}
					style={{
						background: orgTheme && filter === "map" ? orgTheme : "",
					}}
					onClick={() => setFilter("map")}>
					<BsGridFill />
				</span>
				<span className={`flex gap-x-1`} onClick={toggleShowDrawer}>
					<span className={`${filter === "drawer" ? "" : ""}`}>
						<LuSettings2 />
					</span>{" "}
					<span className="text-xs">Filter</span>
				</span>
				{/* </div> */}
			</div>
			{/* <TransformWrapper>
        <TransformComponent> */}
			{firstItem ? (
				<div>
					{filter === "map" && (
						<div className="relative z-30 inset-y-0 flex items-center justify-center">
							<MapVisual
								smallCircle={secondItem?.category || secondItem}
								bigCircle={firstItem?.category || firstItem}
								indexOfFirstItem={indexOfFirstItem}
								indexOfLastItem={indexOfLastItem}
								setTotalPages={setPages}
								mainData1={
									toPaginate === "first"
										? mainData1?.slice(indexOfFirstItem, indexOfLastItem)
										: mainData1
								}
								setMainData1={setMainData1}
								mainData2={
									toPaginate === "second"
										? mainData2.slice(indexOfFirstItem, indexOfLastItem)
										: mainData2
								}
								setMainData2={setMainData2}
							/>
							{secondItem && (
								<div className="v fixed bottom-7 right-4">
									<div className="flex justify-between items-center">
										<div className="mt-3">
											<p className="text-xs font-normal text-[#06094F]">
												Showing page {currentPage} of {totalPages}
											</p>
										</div>
										<div>
											<div className="flex justify-between mt-3 items-center">
												{currentPage > 1 && (
													<div
														className="flex  justify-center items-center py-1 px-2 cursor-pointer"
														onClick={() => handlePageChange(currentPage - 1)}>
														<span>
															<RiArrowLeftSLine />
														</span>
														<span
															disabled={currentPage === 1}
															className="text-black cursor-pointer">
															Prev
														</span>
													</div>
												)}

												<div className="text-[14px] text-[#04242D] border px-[4px] py-[1px] rounded">
													{currentPage}
												</div>

												{currentPage < totalPages && (
													<div
														className="flex  justify-center items-center py-1 px-2 cursor-pointer"
														onClick={() => handlePageChange(currentPage + 1)}>
														<span
															disabled={
																indexOfLastItem >= new Array(200).length
															}
															className="text-black cursor-pointer">
															Next
														</span>
														<span>
															<RiArrowRightSLine />
														</span>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					)}
					{filter === "table" && (
						<div className="mt-28 absolute inset-x-0 z-30">
							<TableView
								smallCircle={secondItem?.category || secondItem}
								bigCircle={firstItem?.category || firstItem}
								mainData1={mainData1}
								mainData2={mainData2}
								setMainData1={setMainData1}
								setMainData2={setMainData2}
							/>
						</div>
					)}
				</div>
			) : (
				<div className="flex flex-col gap-y-2 items-center justify-center absolute inset-0 h-screen z-30">
					<div
						className="h-24 w-24 rounded-full bg-white border border-dashed border-main flex items-center justify-center text-2xl"
						style={{
							borderColor: orgTheme || "",
						}}>
						<IoAddOutline />
					</div>
					<p className="text-center">
						Select any of the categories by the top left to get started
					</p>
				</div>
			)}
			<DrawerModal show={drawer} close={toggleShowDrawer}>
				<div className="h-screen pb-12 scrollbar-hide overflow-y-auto p-4 min-w-[300px] bg-white pt-12 space-y-8">
					{/* {mapData?.map((item, idx) => (
            <div className="">
              <h4 className="font-bold text-md mb-2">
                {item?.displayName || item?.category || item}
              </h4>
              <div className=" outline-none">
                <div className="outline-none">
                  <label for="input-group-search" className="sr-only">
                    Search
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none outline-none">
                      <svg
                        className="w-5 h-5 text-gray-500"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="input-group-search"
                      className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Search user"
                    />
                  </div>
                </div>
                <ul className="p-1 max-h-40 overflow-y-auto text-sm text-gray-700">
                  <li>
                    <div className="flex items-center rounded">
                      <input
                        type="checkbox"
                        value=""
                        className="w-4 h-4 bg-gray-100 border-gray-300 rounded outline-none"
                        onChange={selectAll}
                      />
                      <label
                        for="checkbox-item-11"
                        className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded"
                      >
                        All
                      </label>
                    </div>
                  </li>
                  {item.displayName === "Organization"
                    ? organization?.companies?.docs
                        ?.concat(organization?.companiesArchived?.docs)
                        .map((data) => (
                          <li>
                            <div className="flex items-center rounded">
                              <input
                                type="checkbox"
                                value={data?.organizationName}
                                className="w-4 h-4 bg-gray-100 border-gray-300 rounded outline-none"
                                onClick={selectItem}
                              />
                              <label
                                for="checkbox-item-11"
                                className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded"
                              >
                                {data?.organizationName}
                              </label>
                            </div>
                          </li>
                        ))
                    : rawdata?.data?.docs
                        .filter((data) => data?.category === item?.category)[0]
                        ?.data.map((list) => (
                          <li>
                            <div className="flex items-center rounded">
                              <input
                                type="checkbox"
                                value=""
                                className="w-4 h-4 bg-gray-100 border-gray-300 rounded outline-none"
                              />
                              <label
                                for="checkbox-item-11"
                                className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded"
                              >
                                {list}
                              </label>
                            </div>
                          </li>
                        ))}
                </ul>
                <p className="font-light text-sm text-blue-600">
                  Show All Companies
                </p>
              </div>
            </div>
          ))} */}
					{firstTitle && (
						<FilterTab
							title={firstTitle}
							data={mainData1}
							setData={setMainData1}
						/>
					)}
					{secondTitle && (
						<FilterTab
							title={secondTitle}
							data={mainData2}
							setData={setMainData2}
						/>
					)}
				</div>
			</DrawerModal>
		</div>
	);
};

export default Map;

export const FilterTab = ({ data, title, setData }) => {
	const [newData, setNewData] = useState(null),
		[search, setSearch] = useState("");

	useEffect(() => {
		if (search) {
			document
				.getElementById(`Search${title}`)
				.addEventListener("search", () => {
					setNewData(data);
				});
			let handleSubmit = async () => {
				if (!search) return;

				let ned = data?.filter(it =>
					it?.name?.toLowerCase()?.startsWith(search?.toLowerCase())
				);
				setNewData(ned);
			};
			handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	useEffect(() => {
		if (data) setNewData(data);
	}, [data]);

	if (!newData) return;
	return (
		<>
			<div className="">
				<h4 className="font-bold text-md mb-2">
					{title === "company" ? "Organization" : title}
				</h4>
				<div className=" outline-none">
					<div className="outline-none">
						<label htmlFor={`Search${title}`} className="sr-only">
							Search
						</label>
						<div className="relative">
							<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none outline-none">
								<svg
									className="w-5 h-5 text-gray-500"
									aria-hidden="true"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg">
									<path
										fill-rule="evenodd"
										d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
										clip-rule="evenodd"></path>
								</svg>
							</div>
							<input
								className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
								placeholder={`Search ${
									title === "company" ? "Organization" : title
								}`}
								type="search"
								name="search"
								id={`Search${title}`}
								value={search}
								onChange={e => setSearch(e.target.value)}
							/>
						</div>
					</div>
					<ul className="p-1 max-h-40 overflow-y-auto text-sm text-gray-700">
						<li>
							<div className="flex items-center rounded">
								<input
									type="checkbox"
									value=""
									className="w-4 h-4 bg-gray-100 border-gray-300 rounded outline-none"
									onChange={e => {
										setData(
											data?.map(ie => {
												return { ...ie, isSelected: e?.target?.checked };
											})
										);
									}}
									checked={data?.every(it => it?.isSelected)}
								/>
								<label
									for="checkbox-item-11"
									className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded">
									All
								</label>
							</div>
						</li>
						{newData?.map((it, c) => (
							<li key={c}>
								<div className="flex items-center rounded">
									<input
										type="checkbox"
										// value={data?.organizationName}
										checked={it?.isSelected}
										className="w-4 h-4 bg-gray-100 border-gray-300 rounded outline-none"
										// onClick={selectItem}
										onChange={e => {
											setData(
												data?.map(ie => {
													return ie?.name === it?.name
														? { ...ie, isSelected: e?.target?.checked }
														: ie;
												})
											);
										}}
									/>
									<label
										for="checkbox-item-11"
										className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded">
										{it?.name}
									</label>
								</div>
							</li>
						))}
						{/* {item.displayName === "Organization"
							? organization?.companies?.docs
									?.concat(organization?.companiesArchived?.docs)
									.map(data => (
										<li>
											<div className="flex items-center rounded">
												<input
													type="checkbox"
													value={data?.organizationName}
													className="w-4 h-4 bg-gray-100 border-gray-300 rounded outline-none"
													onClick={selectItem}
												/>
												<label
													for="checkbox-item-11"
													className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded">
													{data?.organizationName}
												</label>
											</div>
										</li>
									))
							: rawdata?.data?.docs
									.filter(data => data?.category === item?.category)[0]
									?.data.map(list => (
										<li>
											<div className="flex items-center rounded">
												<input
													type="checkbox"
													value=""
													className="w-4 h-4 bg-gray-100 border-gray-300 rounded outline-none"
												/>
												<label
													for="checkbox-item-11"
													className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded">
													{list}
												</label>
											</div>
										</li>
									))} */}
					</ul>
					<p className="font-light text-sm text-blue-600">
						Show All {title === "company" ? "Organization" : title}
					</p>
				</div>
			</div>
		</>
	);
};

const TableView = ({
	smallCircle,
	bigCircle,
	mainData1,
	mainData2,
	setMainData1,
	setMainData2,
}) => {
	const [drawer, setDrawer] = useState(false);

	const toggleShowDrawer = it => {
		setDrawer(drawer ? null : it);
	};

	return (
		<div className="w-full overflow-x-auto shadow-md sm:rounded-lg">
			<SortMainData
				bigCircle={bigCircle}
				smallCircle={smallCircle}
				setMainData1={setMainData1}
				setMainData2={setMainData2}
			/>
			<table className="text-sm text-left text-gray-500">
				<thead className="text-md text-gray-700 capitalize bg-secondary">
					<tr>
						<th className="px-6 py-3 max-w-fit">{smallCircle}</th>
						<th scope="col" className="px-6 py-3 w-full">
							<span className="border-l-2 border-gray-300 px-2">
								{bigCircle}
							</span>
						</th>
						<th scope="col" className="px-6 py-3 w-fit"></th>
					</tr>
				</thead>
				<tbody>
					{mainData2
						?.filter(it => it?.isSelected)
						?.map((data, i) => (
							<tr key={i} className="bg-white border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white hover:underline cursor-pointer"
									onClick={() => toggleShowDrawer(data)}>
									{data?.name}
								</th>
								<td className="px-6 py-4">
									<div className="flex gap-1 max-w-2xl overflow-x-auto text-ellipsis pb-2">
										{data?.tools?.map((item, x) =>
											mainData1?.find(
												it => it?.name === item && it?.isSelected
											) ? (
												<ExpertisePill value={item} key={x} />
											) : null
										)}
									</div>
								</td>
								<td className="flex items-center px-6 py-4 space-x-3">
									<div
										class="font-medium cursor-pointer text-blue-600 underline whitespace-nowrap"
										onClick={() => toggleShowDrawer(data)}>
										view All
									</div>
								</td>
							</tr>
						))}
				</tbody>
			</table>
			<DrawerModal show={drawer} close={toggleShowDrawer}>
				<div className="overflow-y-scroll scrollbar-hide flex items-center gap-1 p-4 flex-wrap">
					{drawer?.tools?.map((item, x) =>
						mainData1?.find(it => it?.name === item && it?.isSelected) ? (
							<ExpertisePill value={item} key={x} />
						) : null
					)}
				</div>
			</DrawerModal>
		</div>
	);
};
