import { createSlice } from "@reduxjs/toolkit";
import { DeleteData, EditData } from "./UserReducer";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";
import { manageWorkspace } from "./WorkspaceReducer";
import { getInvitations, getPendingInvitations } from "./InvitationReducer";

let initialState = {
	data: null,
	isAdded: false,
	isDeleted: null,
	search: "",
	mainSearch: null,
	isFound: null,
	isUpdated: null,
	companies: null,
	mapFilter: null,
	logo: null,
	companiesArchived: null,
	companiesRecommendation: null,
};

export const organizationSlice = createSlice({
	name: "organization",
	initialState,
	reducers: {
		getSearchOrganization: (state, { payload }) => {
			if (state?.search === payload?.search) {
				state.mainSearch = payload?.data || payload || state?.mainSearch;
				state.isFound = true;
			}
		},
		getSearch: (state, { payload }) => {
			state.search = payload?.search || payload;
		},
		resetOrganizationSearch: state => {
			state.search = "";
			state.mainSearch = null;
			state.isFound = false;
		},
		getOrganization: (state, { payload }) => {
			state.data = payload?.data || payload;
		},
		getOrganizationCompany: (state, { payload }) => {
			state.companies = payload?.data || payload;
		},
		getOrganizationCSVDownload: (state, { payload }) => {
			state.csvDownload = payload?.data || payload;
		},
		getOrganizationCompanyArchived: (state, { payload }) => {
			state.companiesArchived = payload?.data || payload;
		},
		getOrganizationCompanyRecommendation: (state, { payload }) => {
			state.companiesRecommendation = payload?.data || payload;
		},
		addOrganization: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isAdded = true;
			state.data = {
				...state?.data,
				docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
				totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
			};
		},
		deleteOrganization: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isDeleted = true;
			state.data = {
				...state?.data,
				docs: DeleteData(state?.data?.docs, data),
				totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
			};
		},
		updateOrganization: (state, { payload }) => {
			let data = payload?.data || payload;
			state.isUpdated = true;
			state.data = { ...state?.data, docs: EditData(state?.data?.docs, data) };
		},
		organizationFail: state => {
			state.isDeleted = false;
			state.isAdded = false;
			state.isFound = false;
		},
		logoutOrganization: state => {
			state.data = null;
			state.companies = null;
			state.isAdded = false;
			state.isDeleted = null;
			state.search = "";
			state.mainSearch = null;
			state.isFound = null;
			state.isUpdated = null;
			state.all = null;
		},
	},
});
export const {
	organizationFail,
	getOrg,
	getOrganization,
	deleteOrganization,
	addOrganization,
	getSearchOrganization,
	getSearch,
	resetOrganizationSearch,
	updateOrganization,
	logoutOrganization,
	getOrganizationCompany,
	getOrganizationCompanyArchived,
	getOrganizationCompanyRecommendation,
	getOrganizationCSVDownload,
} = organizationSlice.actions;

export const manageOrganization = (type, data) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;

		if (type === "get") {
			if (data?.search) dispatch(getSearch({ search: data?.search }));

			res = await axios.get(
				`/api/v1/organization${data?.companies ? "/companies" : ""}?type=${
					data?.type || "company"
				}${data?.limit ? `&limit=${data?.limit}` : ""}${
					data?.active ? `&active=${data?.active}` : ""
				}${data?.statusText ? `&statusText=${data?.statusText}` : ""}${
					data?.search ? `&search=${data?.search}` : ""
				}
				${data?.sort ? `&sort=${data?.sort}` : ""}
				${data?.csvDownload ? `&csvDownload=${data?.csvDownload}` : ""}
				`
			);
			dispatch(
				data?.csvDownload
					? getOrganizationCSVDownload(res?.data)
					: data?.search
					? getSearchOrganization(res?.data)
					: data?.statusText === "pending"
					? getPendingInvitations(res?.data)
					: data?.statusText === "activated"
					? getInvitations(res?.data)
					: data?.statusText === "archived"
					? getOrganizationCompanyArchived(res?.data)
					: data?.statusText === "recommendation"
					? getOrganizationCompanyRecommendation(res?.data)
					: data?.companies
					? getOrganizationCompany(res?.data)
					: getOrganization(res?.data)
			);
			if (!data?.companies && !data?.statusText)
				dispatch(manageWorkspace("get"));
		}
		if (type === "post") {
			res = await axios.post(`/api/v1/organization`, { ...data });
			dispatch(addOrganization(res?.data));
		}
		if (type === "put") {
			res = await axios.put(`/api/v1/organization/${data?._id}`, { ...data });
			dispatch(updateOrganization(res?.data));
		}
		if (type === "delete") {
			res = await axios.delete(`/api/v1/organization/${data?._id}`);
			dispatch(deleteOrganization(data));
		}
		if (type !== "get") toast.success(res?.data?.message);
	} catch (err) {
		if (err?.response?.status === 429 || err?.response?.status === 405)
			toast.error(err?.response?.data ? err?.response?.data : err?.message);
		console.log({ err });
		dispatch(organizationFail());
		if (type && type !== "get") {
			let error =
				Array.isArray(err.response?.data?.error) ||
				Array.isArray(err?.response?.data?.message);
			if (error) {
				dispatch(
					returnErrors({
						error: Array.isArray(err.response?.data?.message)
							? err.response?.data?.message
							: err.response?.data?.error,
						status: err?.response?.status,
					})
				);
			} else {
				toast.error(err?.response?.data?.message || err?.response?.data?.error);
			}
		}
	}
};
