import moment from "moment";
import { useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import ModalContainer from "../modal-container/modal-container";
import Button from "../button/button";
import { getOrganizationCSVDownload } from "../../data/Reducers/OrganizationReducer";
import { useDispatch, useSelector } from "react-redux";
import { StringTOCamel } from "../../pages/get-started/steps";

const SearchDropdown = ({ title }) => {
	const [show, setShow] = useState(false);
	return (
		<div className="relative">
			<button
				id="dropdownDefaultButton"
				className="whitespace-nowrap border border-gray-400 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center justify-between w-60 max-w-md"
				type="button"
				onClick={() => setShow(!show)}>
				{title || "Mentor Name"}{" "}
				<svg
					className="w-4 h-4 ml-2"
					aria-hidden="true"
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg">
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M19 9l-7 7-7-7"></path>
				</svg>
			</button>

			{/* <!-- Dropdown menu --> */}
			{show && (
				<div>
					<div
						className="fixed inset-0 bg-man"
						onClick={() => setShow(!show)}></div>
					<div
						id="dropdownSearch"
						className="z-10 absolute mt-2 right-0 bg-white rounded-lg shadow w-60 dark:bg-gray-700">
						<div className="p-3">
							<label for="input-group-search" className="sr-only">
								Search
							</label>
							<div className="relative">
								<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
									<svg
										className="w-5 h-5 text-gray-500 dark:text-gray-400"
										aria-hidden="true"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg">
										<path
											fill-rule="evenodd"
											d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
											clip-rule="evenodd"></path>
									</svg>
								</div>
								<input
									type="text"
									id="input-group-search"
									className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
									placeholder="Search user"
								/>
							</div>
						</div>
						<ul
							className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
							aria-labelledby="dropdownSearchButton">
							<li>
								<div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
									<input
										id="checkbox-item-11"
										type="checkbox"
										value=""
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
									/>
									<label
										for="checkbox-item-11"
										className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
										All
									</label>
								</div>
							</li>
							<li>
								<div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
									<input
										id="checkbox-item-11"
										type="checkbox"
										value=""
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
									/>
									<label
										for="checkbox-item-11"
										className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
										Akros
									</label>
								</div>
							</li>
							<li>
								<div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
									<input
										id="checkbox-item-11"
										type="checkbox"
										value=""
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
									/>
									<label
										for="checkbox-item-11"
										className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
										Citoxi Nigeria LTD
									</label>
								</div>
							</li>
							<li>
								<div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
									<input
										id="checkbox-item-11"
										type="checkbox"
										value=""
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
									/>
									<label
										for="checkbox-item-11"
										className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
										Digital Health Africa
									</label>
								</div>
							</li>
							<li>
								<div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
									<input
										id="checkbox-item-11"
										type="checkbox"
										value=""
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
									/>
									<label
										for="checkbox-item-11"
										className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
										eHealth Africa
									</label>
								</div>
							</li>
							<li>
								<div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
									<input
										id="checkbox-item-11"
										type="checkbox"
										value=""
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
									/>
									<label
										for="checkbox-item-11"
										className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
										Fraym.IO
									</label>
								</div>
							</li>
						</ul>
					</div>
				</div>
			)}
		</div>
	);
};

export default SearchDropdown;

export const SearchDropdownSelect = ({ data, setRaw, rawdata, index }) => {
	const [show, setShow] = useState(false),
		[newData, setNewData] = useState(null),
		[search, setSearch] = useState("");

	useEffect(() => {
		if (search) {
			document.getElementById("Search").addEventListener("search", () => {
				setNewData(data);
			});
			let handleSubmit = async () => {
				if (!search) return;

				let ned = data?.filter(it =>
					it?.toLowerCase()?.startsWith(search?.toLowerCase())
				);
				setNewData(ned);
			};
			handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	useEffect(() => {
		if (data) setNewData(data);
	}, [data]);

	if (!newData) return;

	return (
		<div className="relative">
			<button
				onClick={e => {
					if (e?.target === e?.currentTarget) setShow(!show);
				}}
				className="whitespace-nowrap bg-gray-100 font-medium rounded-lg text-sm px-4 py-4 w-full text-center inline-flex items-center justify-between"
				type="button">
				<div className="flex items-center gap-2 overflow-x-auto max-w-[95%] pb-2">
					{data ? (
						<>
							{rawdata?.docs?.[index]?.selection?.map((iz, d) => (
								<div
									key={d}
									className="flex items-center gap-2 border whitespace-nowrap border-gray-400 rounded-full px-2">
									{iz}{" "}
									<span
										onClick={e => {
											e?.stopPropagation();
											let selectionArr =
												rawdata?.docs?.[index]?.selection || [];
											selectionArr = selectionArr?.filter(it => it !== iz);

											let newRaw = rawdata?.docs?.map((ix, f) =>
												f === index ? { ...ix, selection: selectionArr } : ix
											);
											setRaw({ ...rawdata, docs: newRaw });
										}}
										className="text-gray-300 cursor-pointer">
										<MdCancel />
									</span>
								</div>
							))}
						</>
					) : (
						<>
							{/* <div className="flex items-center gap-2 border whitespace-nowrap border-gray-400 rounded-full px-2">
								Lagos{" "}
								<span className="text-gray-300">
									<MdCancel />
								</span>
							</div>
							<div className="flex items-center gap-2 border whitespace-nowrap border-gray-400 rounded-full px-2">
								Nairobi{" "}
								<span className="text-gray-300">
									<MdCancel />
								</span>
							</div>
							<div className="flex items-center gap-2 border whitespace-nowrap border-gray-400 rounded-full px-2">
								kampala{" "}
								<span className="text-gray-300">
									<MdCancel />
								</span>
							</div> */}
						</>
					)}
				</div>
				<svg
					className="w-4 h-4 ml-2"
					aria-hidden="true"
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
					onClick={() => setShow(!show)}>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M19 9l-7 7-7-7"></path>
				</svg>
			</button>

			{/* <!-- Dropdown menu --> */}
			{show && (
				<div>
					<div
						className="fixed inset-0 bg-man"
						onClick={() => setShow(!show)}></div>
					<div
						id="dropdownSearch"
						className="z-10 absolute mt-2 inset-x-0 bg-white rounded-lg shadow dark:bg-gray-700">
						<div className="p-3">
							<label for="input-group-search" className="sr-only">
								Search
							</label>
							<div className="relative">
								<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
									<svg
										className="w-5 h-5 text-gray-500 dark:text-gray-400"
										aria-hidden="true"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg">
										<path
											fill-rule="evenodd"
											d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
											clip-rule="evenodd"></path>
									</svg>
								</div>
								<input
									className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
									placeholder="Search user"
									type="search"
									name="search"
									id="Search"
									value={search}
									onChange={e => setSearch(e.target.value)}
								/>
							</div>
						</div>
						{newData ? (
							<SearchUL
								data={newData}
								rawdata={rawdata}
								setRaw={setRaw}
								index={index}
							/>
						) : (
							<ul
								className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
								aria-labelledby="dropdownSearchButton">
								{/* <li>
								<div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
									<input
										id="checkbox-item-11"
										type="checkbox"
										value=""
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
									/>
									<label
										for="checkbox-item-11"
										className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
										All
									</label>
								</div>
							</li>
							<li>
								<div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
									<input
										id="checkbox-item-11"
										type="checkbox"
										value=""
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
									/>
									<label
										for="checkbox-item-11"
										className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
										Akros
									</label>
								</div>
							</li>
							<li>
								<div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
									<input
										id="checkbox-item-11"
										type="checkbox"
										value=""
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
									/>
									<label
										for="checkbox-item-11"
										className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
										Citoxi Nigeria LTD
									</label>
								</div>
							</li>
							<li>
								<div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
									<input
										id="checkbox-item-11"
										type="checkbox"
										value=""
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
									/>
									<label
										for="checkbox-item-11"
										className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
										Digital Health Africa
									</label>
								</div>
							</li>
							<li>
								<div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
									<input
										id="checkbox-item-11"
										type="checkbox"
										value=""
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
									/>
									<label
										for="checkbox-item-11"
										className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
										eHealth Africa
									</label>
								</div>
							</li>
							<li>
								<div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
									<input
										id="checkbox-item-11"
										type="checkbox"
										value=""
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
									/>
									<label
										for="checkbox-item-11"
										className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
										Fraym.IO
									</label>
								</div>
							</li> */}
							</ul>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export const SearchUL = ({ data, rawdata, setRaw, index }) => {
	if (!data) return;

	return (
		<ul
			className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
			aria-labelledby="dropdownSearchButton">
			{data?.length <= 10 && (
				<li className="hidden">
					<div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
						<input
							id="checkbox-item-11"
							type="checkbox"
							value=""
							className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
							checked={
								rawdata?.docs?.[index]?.selection?.sort() === data?.sort()
							}
							readOnly={data?.length >= 10}
							onChange={e => {
								let selectionArr = rawdata?.docs?.[index]?.selection || [];
								if (e.target.checked) {
									selectionArr = data;
								} else {
									selectionArr = [];
								}
								let newRaw = rawdata?.docs?.map((ix, f) =>
									f === index ? { ...ix, selection: selectionArr } : ix
								);
								if (data?.length <= 10) setRaw({ ...rawdata, docs: newRaw });
								else {
									toast.info("Maximum selectable selection of 10");
								}
							}}
						/>
						<label
							for="checkbox-item-11"
							className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
							All
						</label>
					</div>
				</li>
			)}
			{data?.map((it, i) => (
				<li key={i}>
					<div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
						<input
							id={it + i}
							type="checkbox"
							value=""
							className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
							checked={rawdata?.docs?.[index]?.selection?.includes(it)}
							readOnly={rawdata?.docs?.[index]?.selection?.length >= 10}
							onChange={e => {
								let selectionArr = rawdata?.docs?.[index]?.selection || [];
								if (e.target.checked) {
									if (selectionArr?.find(tt => tt === it)) {
									} else {
										selectionArr = [...selectionArr, it];
									}
								} else {
									selectionArr = selectionArr?.filter(iw => it !== iw);
								}
								let newRaw = rawdata?.docs?.map((ix, f) =>
									f === index ? { ...ix, selection: selectionArr } : ix
								);
								if (
									!rawdata?.docs?.[index]?.selection ||
									rawdata?.docs?.[index]?.selection?.length < 10
								)
									setRaw({ ...rawdata, docs: newRaw });
								else {
									toast.info("Maximum selectable selection of 10");
								}
							}}
						/>
						<label
							htmlFor={it + i}
							className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
							{it}
						</label>
					</div>
				</li>
			))}
		</ul>
	);
};

export const SearchULCountry = ({ data, rawdata, setRaw, index }) => {
	console.log({ rawdata });
	if (!rawdata) return;

	return (
		<ul
			className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
			aria-labelledby="dropdownSearchButton">
			<li className="hidden">
				<div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
					<input
						id="checkbox-item-11"
						type="checkbox"
						value=""
						className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
						checked={
							rawdata
								?.map(ia => ia?.name?.common)
								?.sort()
								?.toString() === data?.[index]?.sort()?.toString()
						}
						onChange={e => {
							let selectionArr = data?.[index] || [];
							if (e.target.checked) {
								selectionArr = rawdata?.map(ia => ia?.name?.common)?.sort();
							} else {
								selectionArr = [];
							}
							setRaw({ ...data, [index]: selectionArr });
						}}
					/>
					<label
						for="checkbox-item-11"
						className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
						All
					</label>
				</div>
			</li>
			{rawdata?.map((it, i) => (
				<li key={i}>
					<div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
						<input
							id={it?.name?.common}
							type="checkbox"
							value=""
							className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
							checked={data?.[index]?.includes(it?.name?.common)}
							onChange={e => {
								let selectionArr = data?.[index] || [];
								if (e.target.checked) {
									if (selectionArr?.find(tt => tt === it?.name?.common)) {
									} else {
										selectionArr = [...selectionArr, it?.name?.common];
									}
								} else {
									selectionArr = selectionArr?.filter(
										iw => it?.name?.common !== iw
									);
								}

								setRaw({ ...data, [index]: selectionArr });
							}}
						/>
						<label
							htmlFor={it?.name?.common}
							className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
							{it?.name?.common}
						</label>
					</div>
				</li>
			))}
		</ul>
	);
};

export const SearchDropdownSelectCountry = ({
	data,
	setRaw,
	rawdata,
	index,
}) => {
	const [show, setShow] = useState(false),
		[newData, setNewData] = useState(null),
		[search, setSearch] = useState("");

	useEffect(() => {
		if (search) {
			document.getElementById("Search").addEventListener("search", () => {
				setNewData(rawdata);
			});
			let handleSubmit = async () => {
				if (!search) return;

				let ned = rawdata?.filter(it =>
					it?.name?.common?.toLowerCase()?.startsWith(search?.toLowerCase())
				);
				setNewData(ned);
			};
			handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	useEffect(() => {
		if (rawdata) setNewData(rawdata);
	}, [rawdata]);

	if (!newData) return;

	return (
		<div className="relative">
			<button
				onClick={e => {
					if (e?.target === e?.currentTarget) setShow(!show);
				}}
				className="whitespace-nowrap bg-gray-100 font-medium rounded-lg text-sm px-4 py-4 w-full text-center inline-flex items-center justify-between"
				type="button">
				<div className="flex items-center gap-2 overflow-x-auto max-w-[95%] pb-2">
					{data ? (
						<>
							{data?.[index]?.map((iz, d) => (
								<div
									key={d}
									className="flex items-center gap-2 border whitespace-nowrap border-gray-400 rounded-full px-2">
									{iz}{" "}
									<span
										onClick={e => {
											e?.stopPropagation();
											let selectionArr = data?.[index] || [];
											selectionArr = selectionArr?.filter(it => it !== iz);

											setRaw({ ...data, [index]: selectionArr });
										}}
										className="text-gray-300 cursor-pointer">
										<MdCancel />
									</span>
								</div>
							))}
						</>
					) : (
						<></>
					)}
				</div>
				<svg
					className="w-4 h-4 ml-2"
					aria-hidden="true"
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
					onClick={() => setShow(!show)}>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M19 9l-7 7-7-7"></path>
				</svg>
			</button>

			{/* <!-- Dropdown menu --> */}
			{show && (
				<div>
					<div
						className="fixed inset-0 bg-man"
						onClick={() => setShow(!show)}></div>
					<div
						id="dropdownSearch"
						className="z-10 absolute mt-2 inset-x-0 bg-white rounded-lg shadow dark:bg-gray-700">
						<div className="p-3">
							<label for="input-group-search" className="sr-only">
								Search
							</label>
							<div className="relative">
								<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
									<svg
										className="w-5 h-5 text-gray-500 dark:text-gray-400"
										aria-hidden="true"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg">
										<path
											fill-rule="evenodd"
											d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
											clip-rule="evenodd"></path>
									</svg>
								</div>
								<input
									className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
									placeholder="Search Location"
									type="search"
									name="search"
									id="Search"
									value={search}
									onChange={e => setSearch(e.target.value)}
								/>
							</div>
						</div>
						{newData ? (
							<SearchULCountry
								data={data}
								rawdata={newData}
								setRaw={setRaw}
								index={index}
							/>
						) : (
							<ul
								className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
								aria-labelledby="dropdownSearchButton"></ul>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export const SearchDropdownSelectExport = ({ fileName, newSample }) => {
	const [show, setShow] = useState(false);
	return (
		<div className="relative">
			<button
				className="whitespace-nowrap bg-white border font-medium rounded-lg text-sm px-4 py-3 w-full text-center inline-flex items-center justify-between"
				type="button">
				<div className="flex items-center gap-2 overflow-x-auto max-w-[95%]">
					Export as
				</div>
				<svg
					className="w-4 h-4 ml-2"
					aria-hidden="true"
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
					onClick={() => setShow(!show)}>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M19 9l-7 7-7-7"></path>
				</svg>
			</button>

			{/* <!-- Dropdown menu --> */}
			{show && (
				<div className="z-10 absolute mt-2 inset-x-0 bg-white rounded-lg shadow dark:bg-gray-700">
					{newSample ? (
						<SearchExportUL fileName={fileName} newSample={newSample} />
					) : (
						<ul
							className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
							aria-labelledby="dropdownSearchButton"></ul>
					)}
				</div>
			)}
		</div>
	);
};

export const SortBy = ({ sort, setSort }) => {
	return (
		<>
			<div className="flex items-center gap-2">
				<p></p>
				<select
					onChange={e => setSort(e?.target.value)}
					value={sort}
					className="rounded-lg">
					<option>Sort</option>
					<option value={"dsc"}>Most Recent</option>
					<option value={"asc"}>Least Recent</option>
				</select>
			</div>
		</>
	);
};

export const SortByCompany = ({ data, setData, title, options }) => {
	// console.log({ options });
	return (
		<>
			<div className="flex items-center gap-2">
				<p>{title || "Sort By"}</p>
				<select
					onChange={e => setData(e?.target.value)}
					value={data}
					className="rounded-lg md:w-1/2">
					<option>Select Organization</option>
					{options
						?.filter(is => is)
						?.map((it, i) => (
							<option key={i} value={it?._id}>
								{it?.organizationName}
							</option>
						))}
				</select>
			</div>
		</>
	);
};

export const SearchExportUL = ({ fileName, newSample }) => {
	return (
		<ul
			className="min-h-10 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
			aria-labelledby="dropdownSearchButton">
			<li>
				<CSVLink
					filename={`${fileName} ${moment().format("YYYYMMDDHHMMASSS")}.csv`}
					className="text-main flex items-center gap-1 w-full"
					data={newSample}>
					<div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
						<label
							for="checkbox-item-11"
							className="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
							CSV
						</label>
					</div>
				</CSVLink>
			</li>
		</ul>
	);
};

export const ExportAsCsvDownload = ({
	isOpen,
	setIsOpen,
	loading,
	handleLoadMore,
	type,
}) => {
	const dispatch = useDispatch(),
		{ organization, auth, rawdata } = useSelector(s => s),
		[newSample, setNewSample] = useState([]),
		[canDownload, setCanDownload] = useState(false);

	useEffect(() => {
		dispatch(getOrganizationCSVDownload(null));
		setCanDownload(false);
	}, [dispatch]);

	useEffect(() => {
		if (organization?.csvDownload) {
			let newArr = [],
				newHeader = [
					"Organization Name",
					"Website Url",
					"LinkedIn Url",
					"Company Bio",
					"Head Quarters",
					"Email",
					"Contact Person Name",
					"Contact Person Email",
					"Phone Number",
					"Logo",
					"Office Locations",
					"Locations Paid Staff",
					"Organization Mission",
					"Year Founded",
					"Associated Person",
					"Associated Person Email",
				];
			let newObj = {};
			if (rawdata?.data?.docs?.length > 0) {
				for (let r = 0; r < rawdata?.data?.docs.length; r++) {
					let element = rawdata?.data?.docs[r];
					// newObj[`${StringTOCamel(element?.category)}`] = element?.data
					// 	?.toString()
					// 	?.split(",")
					// 	?.join("\n");

					newHeader = [element?.category, ...newHeader];
				}
				// newArr?.push({ ...newObj, ...OrgDataSample?.[0] });
			}
			if (organization?.csvDownload?.docs?.length > 0) {
				for (let r = 0; r < organization?.csvDownload?.docs.length; r++) {
					let element = organization?.csvDownload?.docs[r];
					newObj = {
						organizationName:
							auth?.user?.privilege === "organization"
								? element.company?.organizationName || element?.organizationName
								: element?.organizationName ||
								  element.company?.organizationName,
						email: element?.email,
						role: element?.privilege,
						dateAdded: moment(element?.createdAt).format("L"),
					};
					if (element?.websiteUrl) newObj.websiteUrl = element?.websiteUrl;
					if (element?.linkedInUrl) newObj.linkedInUrl = element?.linkedInUrl;
					if (element?.phoneNumber) newObj.phoneNumber = element?.phoneNumber;
					if (element?.companyBio) newObj.companyBio = element?.companyBio;
					if (element?.organizationMission)
						newObj.organizationMission = element?.organizationMission;
					if (element?.officeLocations)
						newObj.officeLocations = element?.officeLocations
							// ?.toString()
							// ?.split(",")
							?.join("\n");
					if (element?.headQuarters)
						newObj.headQuarters = element?.headQuarters;
					if (element?.locationsPaidStaff)
						newObj.locationsPaidStaff = element?.locationsPaidStaff
							// ?.toString()
							// ?.split(",")
							?.join("\n");
					if (element?.image?.url) newObj.logo = element?.image?.url;
					if (element?.yearFounded)
						newObj.yearFounded = moment(element?.yearFounded).format("L");
					if (element?.contactPerson?.name)
						newObj.contactPersonName = element?.contactPerson?.name;
					if (element?.contactPerson?.email)
						newObj.contactPersonEmail = element?.contactPerson?.email;
					if (element?.rawdata && element?.rawdata?.length > 0) {
						for (let rd = 0; rd < element?.rawdata?.length; rd++) {
							let elementRD = element?.rawdata?.[rd];
							newObj[`${StringTOCamel(elementRD?.category)}`] = elementRD?.data
								?.toString()
								?.split(",")
								?.join("\n");
						}
					}
					if (
						element?.associatePerson &&
						element?.associatePerson?.length > 0
					) {
						let newNames = element?.associatePerson?.map(ic => {
							return auth?.user?.privilege === "organization"
								? ic.company?.organizationName || ic?.organizationName
								: ic?.organizationName || ic?.company?.organizationName;
						});
						newObj[`${StringTOCamel("Associated Person")}`] = newNames
							// ?.toString()
							// ?.split(",")
							?.join("\n");
						let newEmails = element?.associatePerson?.map(ic => {
							return ic?.email || ic?.company?.email;
						});
						newObj[`${StringTOCamel("Associated Person Email")}`] = newEmails
							// ?.toString()
							// ?.split(",")
							?.join("\n");
						console.log({
							newEmails,
							newNames,
							email: newObj?.associatedPersonEmail,
							name: newObj?.associatedPerson,
						});
					}

					newArr?.push(newObj);
					newObj = {};
				}
			}
			let newH = [...new Set(newHeader?.map(it => it))];
			let csvData = [
				newH,
				...newArr?.map(it => {
					const obj = Object.fromEntries(newH.map(key => [key, null]));
					let newA = Object?.keys(obj)?.map(ic => it?.[`${StringTOCamel(ic)}`]);
					return newA;
				}),
			];
			console.log({ csvData });
			setNewSample(csvData);
		}
	}, [organization?.csvDownload, auth?.user, rawdata]);

	return (
		<>
			<ModalContainer
				title={`Download ${type || "Option"}`}
				width={"max-w-sm"}
				show={isOpen}
				close={() => setIsOpen(null)}>
				<div className="mx-20">
					<form className="space-y-4">
						<div className="my-auto w-100">
							<p className="text2 Lexend text-center">
								Do you want to download {type} data?
							</p>
							<div className="pt-4 flex">
								{canDownload ? (
									<>
										{newSample?.length > 0 && (
											<>
												<CSVLink
													filename={`${type} ${moment().format(
														"YYYYMMDDHHMMASSS"
													)}.csv`}
													className="text-main flex items-center gap-1 w-full"
													data={newSample}>
													<div className="flex items-center px-2 py-3 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
														<label
															for="checkbox-item-11"
															className="w-full py-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
															Click to save CSV
														</label>
													</div>
												</CSVLink>
											</>
										)}
									</>
								) : (
									<>
										<Button
											buttonType={"primary"}
											children={"Yes"}
											type="button"
											css={"w-fit me-2 py-3"}
											loading={loading === "csv"}
											onClick={async () => {
												await handleLoadMore("csv");
												setCanDownload(true);
											}}
										/>
										<Button
											buttonType={"secondary"}
											disabled={loading}
											children={"No"}
											type="button"
											css={"w-fit ms-2 py-3"}
											onClick={() => setIsOpen(null)}
										/>
									</>
								)}
							</div>
						</div>
					</form>
				</div>
			</ModalContainer>
		</>
	);
};